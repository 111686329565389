import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import {
  BaseProduct,
  ProductList,
} from "@store/plan-creation/plan-creation-slice-helper-types";
import {
  AllowedAddonsTypes,
  AllowedBundlesTypes,
  AllowedCustomProductTypes,
  AllowedFeatureTypes,
  AllowedSubscriptionsTypes,
  AllowedTrialProductTypes,
} from "@utils/product/product-types";

/* eslint-disable @typescript-eslint/naming-convention */
/** List of all available features in Admin Panel */
const allAvailableFeatures: Record<AllowedFeatureTypes, BaseProduct> = {
  [APITypes.EUserSubscriptionRole.globalFaceBlurring]: {
    identifier: APITypes.EUserSubscriptionRole.globalFaceBlurring,
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [APITypes.EUserSubscriptionRole.ft_spotwalk]: {
    identifier: APITypes.EUserSubscriptionRole.ft_spotwalk,
    scope: { [AdminAPITypes.ESubjectType.project]: false },
  },

  [APITypes.EUserSubscriptionRole.ft_download_360]: {
    identifier: APITypes.EUserSubscriptionRole.ft_download_360,
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [APITypes.EUserSubscriptionRole.ft_project_evaluation]: {
    identifier: APITypes.EUserSubscriptionRole.ft_project_evaluation,
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [APITypes.EUserSubscriptionRole.projectProgress]: {
    identifier: APITypes.EUserSubscriptionRole.projectProgress,
    scope: { [AdminAPITypes.ESubjectType.project]: false },
  },

  [APITypes.EUserSubscriptionRole.insightsTeaser]: {
    identifier: APITypes.EUserSubscriptionRole.insightsTeaser,
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [APITypes.EUserSubscriptionRole.globalVideoMode]: {
    identifier: APITypes.EUserSubscriptionRole.globalVideoMode,
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [APITypes.EUserSubscriptionRole.globalProgressAi]: {
    identifier: APITypes.EUserSubscriptionRole.globalProgressAi,
    scope: { [AdminAPITypes.ESubjectType.project]: false },
  },

  [APITypes.EUserSubscriptionRole.globalPointCloudSendTo]: {
    identifier: APITypes.EUserSubscriptionRole.globalPointCloudSendTo,
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [APITypes.EUserSubscriptionRole.sphereDashboard]: {
    identifier: APITypes.EUserSubscriptionRole.sphereDashboard,
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },
};

/** List of all available bundles in Admin Panel */
const allAvailableBundles: Record<AllowedBundlesTypes, BaseProduct> = {
  [AdminAPITypes.EFeatureBundleIdentifier.dashboard]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.dashboard,
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.education]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.education,
    scope: {
      [AdminAPITypes.ESubjectType.company]: true,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.planFm]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.planFm,
    scope: {
      [AdminAPITypes.ESubjectType.company]: true,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.premiumInsights]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.premiumInsights,
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.gps]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.gps,
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.snapshot]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.snapshot,
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.vpm]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.vpm,
    scope: { [AdminAPITypes.ESubjectType.project]: true },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.vpmEnterprise]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.vpmEnterprise,
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.pointCloud]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.pointCloud,
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.cloudProcessing]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.cloudProcessing,
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.cad]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.cad,
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.flash]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.flash,
    scope: { [AdminAPITypes.ESubjectType.device]: false },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.faroStream]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.faroStream,
    scope: { [AdminAPITypes.ESubjectType.device]: false },
  },
};

/** List of all available subscriptions in Admin Panel */
const allAvailableSubscriptions: Record<
  AllowedSubscriptionsTypes,
  BaseProduct
> = {
  [AdminAPITypes.EFeatureBundleIdentifier.sphereXGStandard]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.sphereXGStandard,
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.sphereXGPro]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.sphereXGPro,
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },
};

/** List of all available addons in Admin Panel */
const allAvailableAddons: Record<AllowedAddonsTypes, BaseProduct> = {
  [AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },
};

/**
 * List of all available custom products in Admin Panel
 * The list of identifier cannot be added here as array. This is because the identifier is stored as selectId in slice
 * and redux toolkit does not allow array as key in createSlice
 * Mapping identifier to array of bundle identifications is done in customProductIdToBundleIdentification function
 */
export const allAvailableCustomProducts: Record<
  AllowedCustomProductTypes,
  Required<BaseProduct>
> = {
  standard360: {
    identifier: "standard360",
    name: "360° Standard",
    description: "",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  standardPointCloud: {
    identifier: "standardPointCloud",
    name: "Point Cloud Standard",
    description: "",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  pro360: {
    identifier: "pro360",
    name: "360° Professional",
    description: "",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  proPointCloud: {
    identifier: "proPointCloud",
    name: "Point Cloud Professional",
    description: "",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  enterprise360: {
    identifier: "enterprise360",
    name: "360° Enterprise",
    description: "",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  enterprisePointCloud: {
    identifier: "enterprisePointCloud",
    name: "Point Cloud Enterprise",
    description: "",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  enterprise360PointCloud: {
    identifier: "enterprise360PointCloud",
    name: "Point Cloud & 360° Enterprise",
    description: "",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },
};

/** List of all available trial products in Admin Panel */
export const allAvailableTrialProducts: Record<
  AllowedTrialProductTypes,
  Required<BaseProduct>
> = {
  enterprise360Trial: {
    identifier: "enterprise360Trial",
    name: "360° Trial",
    description: "The trial version of 360° Enterprise",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  enterprisePointCloudTrial: {
    identifier: "enterprisePointCloudTrial",
    name: "Point Cloud Trial",
    description: "The trial version of Point Cloud Enterprise",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  enterprise360PointCloudTrial: {
    identifier: "enterprise360PointCloudTrial",
    name: "Point Cloud & 360° Trial",
    description: "The trial version of Point Cloud & 360° Enterprise",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },
};
/* eslint-enable */

/** All the possible products in Admin Panel */
export const allProducts: ProductList = {
  feature: allAvailableFeatures,
  bundle: allAvailableBundles,
  subscription: allAvailableSubscriptions,
  addon: allAvailableAddons,
  custom: allAvailableCustomProducts,
  trial: allAvailableTrialProducts,
};
